<template>
    <div class="inner-pages hd-white">
    <div id="wrapper">
         <img :src="require('../assets/images/Vencasa-Leaf-Top-Border-4K.webp')" />
        <div id="login">
            <div class="login">
                <form @submit.prevent="" autocomplete="off">
                    <div class="form-group username">
                        <label>Username</label>
                        <input :class="($v.username.$dirty && !$v.username.required) ? 'error-border' : ''" v-model="username" class="form-control" type="text">
                        <span class="error" v-if="$v.username.$dirty && !$v.username.required">This field is required!</span>
                        <span class="error" v-if="usernameError">This username has already been taken</span>
                    </div>
                    <div class="form-group">
                        <label>First Name</label>
                        <input :class="($v.firstName.$dirty && !$v.firstName.required) ? 'error-border' : ''" v-model="firstName" class="form-control" type="text">
                        <span class="error" v-if="$v.firstName.$dirty && !$v.firstName.required">This field is required!</span>
                    </div>
                    <div class="form-group">
                        <label>Last Name</label>
                        <input :class="($v.lastName.$dirty && !$v.lastName.required) ? 'error-border' : ''" v-model="lastName" class="form-control" type="text">
                        <span class="error" v-if="$v.lastName.$dirty && !$v.lastName.required">This field is required!</span>
                    </div>
                    <div class="form-group email">
                        <label>Email</label>
                        <input :class="($v.email.$dirty && !$v.email.required) ? 'error-border' : ''" v-model="email" class="form-control" type="email">
                        <span class="error" v-if="$v.email.$dirty && !$v.email.required">This field is required!</span>
                        <span class="error" v-if="emailError">This email has already been taken</span>
                    </div>
                    <div class="form-group">
                        <label>Password</label>
                        <input :class="(($v.password.$dirty && !$v.password.required) || ($v.password.$dirty && !$v.password.minLength)) ? 'error-border' : ''" v-model="password" class="form-control" type="password" id="password1">
                        <span class="error" v-if="$v.password.$dirty && !$v.password.required">This field is required!</span>
                        <span class="error" v-if="$v.password.$dirty && !$v.password.minLength">Password must be 8 digits long</span>
                    </div>
                    <div class="form-group">
                        <label>Confirm Password</label>
                        <input :class="(!$v.passwordConfirmation.sameAsPassword && clicked) ? 'error-border' : ''" v-model="passwordConfirmation" class="form-control" type="password" id="password2">
                        <span class="error" v-if="!$v.passwordConfirmation.sameAsPassword && clicked">Passwords must match</span>
                    </div>
                    <div id="pass-info" class="clearfix"></div>
                    <button @click="register()" style="outline: none;" class="btn_1 rounded full-width add_top_30">Register Now!</button>
                    <div class="text-center add_top_10">Already have an acccount? <strong><router-link to="/login">Sign In</router-link></strong></div>
                </form>
            </div>
        </div>
        <div class="login-and-register-form modal">
            <div class="main-overlay"></div>
            <div class="main-register-holder">
                <div class="main-register fl-wrap">
                    <div class="close-reg"><i class="fa fa-times"></i></div>
                    <h3>Welcome to <span>Find<strong>Houses</strong></span></h3>
                    <div class="soc-log fl-wrap">
                        <p>Login</p>
                        <a href="#" class="facebook-log"><i class="fa fa-facebook-official"></i>Log in with Facebook</a>
                        <a href="#" class="twitter-log"><i class="fa fa-twitter"></i> Log in with Twitter</a>
                    </div>
                    <div class="log-separator fl-wrap"><span>Or</span></div>
                    <div id="tabs-container">
                        <ul class="tabs-menu">
                            <li class="current"><a href="#tab-1">Login</a></li>
                            <li><a href="#tab-2">Register</a></li>
                        </ul>
                        <div class="tab">
                            <div id="tab-1" class="tab-contents">
                                <div class="custom-form">
                                    <form method="post" name="registerform">
                                        <label>Username or Email Address * </label>
                                        <input name="email" type="text" value="">
                                        <label>Password * </label>
                                        <input name="password" type="password" value="">
                                        <button type="submit" class="log-submit-btn"><span>Log In</span></button>
                                        <div class="clearfix"></div>
                                        <div class="filter-tags">
                                            <input id="check-a" type="checkbox" name="check">
                                            <label for="check-a">Remember me</label>
                                        </div>
                                    </form>
                                    <div class="lost_password">
                                        <a href="#">Lost Your Password?</a>
                                    </div>
                                </div>
                            </div>
                            <div class="tab">
                                <div id="tab-2" class="tab-contents">
                                    <div class="custom-form">
                                        <form @submit.prevent="" name="registerform" class="main-register-form" id="main-register-form2">
                                            <label>Nickname *</label>
                                            <input name="name3" v-model="username" type="text" value="">
                                            <label>First Name * </label>
                                            <input name="name" v-model="firstName" type="text" value="">
                                            <label>Second Name *</label>
                                            <input name="name2" v-model="lastName" type="text" value="">
                                            <label>Email Address *</label>
                                            <input name="email" v-model="email" type="text" value="">
                                            <label>Password *</label>
                                            <input name="password" v-model="password" type="password" value="">
                                            <span class="field"></span>
                                            <label>Confirm Password *</label>
                                            <input name="password" v-model="passwordConfirmation" type="password" value="">
                                            <button @click="register()" type="submit" class="log-submit-btn"><span>Register</span></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import { required, minLength, email, sameAs } from "vuelidate/lib/validators"
export default {
    mounted(){
        this.getRefCode()
    },
    watch:{
        email(){
            if(this.hasClicked)
                this.emailError = false
        },
        username(){
            if(this.hasClicked)
                this.usernameError = false
        }
    },
    data(){
        return{
            hasClicked: false,
            usernameError: false,
            emailError: false,
            username: "",
            ref_code: null,
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            passwordConfirmation: "",
            clicked: false,
        }
    },
    validations:{
        firstName: {
            required
        },
        lastName: {
            required
        },
        username: {
            required
        },
        email:{
            required,
            email
        },
        password:{
            required,
            minLength: minLength(8)
        },
        passwordConfirmation:{
            required,
            sameAsPassword: sameAs('password')
        }
    },
    methods:{
        getRefCode(){
            let cookies = document.cookie.split(';')
            for(let i = 0; i < cookies.length; i++){
                let splitCookie = cookies[i].trim().split('=')
                for(let j = 0; j < splitCookie.length; j++){
                    if(splitCookie[j] == 'ref_code')
                        this.ref_code = splitCookie[j+1]
                }
            }
        },
        async register(){
            if(this.$v.$invalid){
                if(this.$v.firstName.$invalid)
                    this.$v.firstName.$touch()
                if(this.$v.lastName.$invalid)
                    this.$v.lastName.$touch()
                if(this.$v.username.$invalid)
                    this.$v.username.$touch()
                if(this.$v.email.$invalid)
                    this.$v.email.$touch()
                if(this.$v.password.$invalid)
                    this.$v.password.$touch()
                if(this.$v.passwordConfirmation.$invalid)
                    this.$v.passwordConfirmation.$touch()
                this.clicked = true
            }
            else{
                this.clicked = false
                this.hasClicked = true
                this.$store.commit('setShowLoader', true)
                const data = {
                    name: this.firstName + ' ' + this.lastName,
                    email: this.email,
                    password: this.password,
                    user_name: this.username,
                    password_confirmation: this.passwordConfirmation
                }
                await this.$store.dispatch('register', data).then(res => {
                    this.$store.commit('setShowLoader', false)
                    if(res == true){
                        if(this.ref_code){
                        this.$store.dispatch('sendRequestIfCookie', this.ref_code).then(res => {
                            if(res == true){
                                this.$router.push('/email-verification')
                            }
                        })
                        }
                        else
                            this.$router.push('/email-verification')
                    }
                    else if(res.data.code == 422 && res.data.errors.email){
                        this.emailError = true
                        document.querySelector('.email').scrollIntoView({behavior: 'smooth'})
                    }
                    else if(res.data.code == 422 && res.data.errors.user_name){
                        this.usernameError = true
                        document.querySelector('.username').scrollIntoView({behavior: 'smooth'})
                    }
                    else if(res.data.code == 422 && res.data.errors.password){
                        alert("The passwords must match")
                    }
                })
            }
        }
    },
};
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>
</style>
